import { useEffect, useState } from 'react';
import { Chart, CategoryScale, LinearScale, BarElement, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { generateChartData, generateChartLabels } from '../../utils/chart.util';
import { STATS_START_DETAILS } from '../../common/constants/date.constant';

Chart.register(CategoryScale, BarElement, LinearScale, Tooltip);

const APPRECIATION_CHART_DATA_TYPE = {
    ALL: 'all',
    THANKYOU: 'thankyou',
    GIFT: 'gift',
};

const currentDate = new Date();

export function EngagementsChart({ stats, year, dateRangeStart, dateRangeEnd, isLoading }) {
    const [chartLabels, setChartLabels] = useState([]);
    const [chartDatasets, setChartDatasets] = useState([]);
    const [appreciationChartDataType, setAppreciationChartDataType] = useState(APPRECIATION_CHART_DATA_TYPE.ALL);

    useEffect(() => {
        if (stats) {
            generateChart();
        }
    }, [stats, appreciationChartDataType]);

    function generateChart() {
        let thankyouData = [];
        let thankyouCounts = generateCountsByMonthFromCreatedAt(stats.thankyouData);
        let giftData = [];
        let giftCounts = generateCountsByMonthFromCreatedAt(stats.giftData);

        if (year) {
            const startMonth = year === STATS_START_DETAILS.YEAR ? STATS_START_DETAILS.MONTH : 1;
            const endMonth = year === currentDate.getFullYear() ? currentDate.getMonth() + 1 : 12;
            const generatedChartLabels = generateChartLabels(year, startMonth, year, endMonth);
            setChartLabels(generatedChartLabels);
            thankyouData = generateChartData(thankyouCounts, false, year, startMonth, year, endMonth);
            giftData = generateChartData(giftCounts, false, year, startMonth, year, endMonth);
        } else if (dateRangeStart && dateRangeEnd) {
            const startDate = new Date(dateRangeStart);
            const endDate = new Date(dateRangeEnd);
            const generatedChartLabels = generateChartLabels(startDate.getFullYear(), startDate.getMonth() + 1, endDate.getFullYear(), endDate.getMonth() + 1);
            setChartLabels(generatedChartLabels);
            thankyouData = generateChartData(thankyouCounts, false, startDate.getFullYear(), startDate.getMonth() + 1, endDate.getFullYear(), endDate.getMonth() + 1);
            giftData = generateChartData(giftCounts, false, startDate.getFullYear(), startDate.getMonth() + 1, endDate.getFullYear(), endDate.getMonth() + 1);
        } else {
            const generatedChartLabels = generateChartLabels(STATS_START_DETAILS.YEAR, STATS_START_DETAILS.MONTH, currentDate.getFullYear(), currentDate.getMonth() + 1);
            setChartLabels(generatedChartLabels);
            thankyouData = generateChartData(thankyouCounts, false, STATS_START_DETAILS.YEAR, STATS_START_DETAILS.MONTH, currentDate.getFullYear(), currentDate.getMonth() + 1);
            giftData = generateChartData(giftCounts, false, STATS_START_DETAILS.YEAR, STATS_START_DETAILS.MONTH, currentDate.getFullYear(), currentDate.getMonth() + 1);
        }

        const datasets = [];

        if (appreciationChartDataType === APPRECIATION_CHART_DATA_TYPE.ALL || appreciationChartDataType === APPRECIATION_CHART_DATA_TYPE.THANKYOU) {
            datasets.push({
                label: 'Thank You',
                data: thankyouData,
                backgroundColor: '#EE6F57',
                borderColor: '#EE6F57',
            });
        }

        if (appreciationChartDataType === APPRECIATION_CHART_DATA_TYPE.ALL || appreciationChartDataType === APPRECIATION_CHART_DATA_TYPE.GIFT) {
            datasets.push({
                label: 'Gift',
                data: giftData,
                backgroundColor: '#517DEE',
                borderColor: '#517DEE',
            });
        }

        setChartDatasets(datasets);
    }

    const dataLength = chartDatasets[0]?.data?.length || 0;
    const chartContainerWidth = 200 + (dataLength * 10);

    return (
        <div className={`card shadow border-0 p-3 ${isLoading ? 'custom-skeleton-loader-full' : ''}`}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="align-items-center mb-0">Engagements</h5>
            </div>

            <div className='d-flex justify-content-end'>
                <div className="form-check mr-2">
                    <input
                        className="form-check-input"
                        type="radio"
                        id="appreciation-chart-data-type-all"
                        name='appreciation-chart-data-type'
                        value={APPRECIATION_CHART_DATA_TYPE.ALL}
                        onChange={(e) => setAppreciationChartDataType(e.target.value)}
                        checked={appreciationChartDataType === APPRECIATION_CHART_DATA_TYPE.ALL}
                    />
                    <label className="form-check-label" htmlFor="appreciation-chart-data-type-all">All</label>
                </div>
                <div className="form-check mr-2">
                    <input
                        className="form-check-input"
                        type="radio"
                        id="appreciation-chart-data-type-thankyou"
                        name='appreciation-chart-data-type'
                        value={APPRECIATION_CHART_DATA_TYPE.THANKYOU}
                        onChange={(e) => setAppreciationChartDataType(e.target.value)}
                        checked={appreciationChartDataType === APPRECIATION_CHART_DATA_TYPE.THANKYOU}
                    />
                    <label className="form-check-label" htmlFor="appreciation-chart-data-type-thankyou">Thank You</label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        id="appreciation-chart-data-type-gift"
                        name='appreciation-chart-data-type'
                        value={APPRECIATION_CHART_DATA_TYPE.GIFT}
                        onChange={(e) => setAppreciationChartDataType(e.target.value)}
                        checked={appreciationChartDataType === APPRECIATION_CHART_DATA_TYPE.GIFT}
                    />
                    <label className="form-check-label" htmlFor="appreciation-chart-data-type-gift">Gift</label>
                </div>
            </div>

            <div style={{ overflowX: 'scroll', maxWidth: '600px', maxHeight: '600px' }}>
                <div style={{ width: `${chartContainerWidth > 600 ? chartContainerWidth : '600'}px` }}>
                    <Bar
                        data={{
                            labels: chartLabels,
                            datasets: chartDatasets,
                        }}
                        options={{
                            plugins: {
                                tooltip: {
                                    enabled: true,
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

const START_YEAR = 2022;
const currentYear = currentDate.getFullYear();
const YEARS = [];
const MONTHS = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

for (let i = START_YEAR; i <= currentYear; i++) {
    YEARS.push(i);
}

function generateCountsByMonthFromCreatedAt(data = []) {
    const counts = {};
    const initialCountByMonths = {};

    MONTHS.forEach((month) => {
        initialCountByMonths[month] = 0;
    });

    YEARS.forEach((year) => {
        counts[year] = { ...initialCountByMonths };
    });

    data.forEach((item) => {
        const createdDate = new Date(item.createdAt);
        const year = createdDate.getFullYear();
        const month = createdDate.getMonth();

        const monthName = MONTHS[month];

        counts[year][monthName]++;
    });

    return counts;
}
