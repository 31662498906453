import { useQuery } from '@tanstack/react-query';
import { UptakeChart } from './UptakeChart';
import { EngagementsChart } from './EngagementsChart';
import statsService from '../../Services/stats.service';

export function DashboardCharts({ filters, selectedYear, dateRangeStart, dateRangeEnd }) {
    const { data: appreciationStatsForChartsRes, isLoading } = useQuery({
        queryKey: ['appreciation-stats-charts-data', {
            year: filters.year !== "range" ? filters.year : undefined,
            organizationId: filters.organizationId,
            teamIds: filters.teamIds,
            platformFlow: filters.platformFlow,
            dateRangeStart: filters.year === "range" ? filters.dateRangeStart : undefined,
            dateRangeEnd: filters.year === "range" ? filters.dateRangeEnd : undefined,
        }],
        queryFn: () => statsService.getAppreciationStatsForCharts({
            year: filters.year !== "range" ? filters.year : undefined,
            organizationIds: filters.organizationId ? [filters.organizationId] : [],
            teamIds: filters.teamIds,
            platformFlow: filters.platformFlow,
            dateRangeStart: filters.year === "range" ? filters.dateRangeStart : undefined,
            dateRangeEnd: filters.year === "range" ? filters.dateRangeEnd : undefined,
        }),
        staleTime: Infinity,
    });
    const appreciationStatsForCharts = appreciationStatsForChartsRes?.data;

    return (
        <div className='row mt-4'>
            <div className='col-12 col-xl-6'>
                <UptakeChart
                    stats={appreciationStatsForCharts}
                    year={selectedYear !== "range" ? parseInt(selectedYear) : undefined}
                    dateRangeStart={selectedYear === "range" ? dateRangeStart : undefined}
                    dateRangeEnd={selectedYear === "range" ? dateRangeEnd : undefined}
                    isLoading={isLoading}
                />
            </div>

            <div className='col-12 col-xl-6'>
                <EngagementsChart
                    stats={appreciationStatsForCharts}
                    year={selectedYear !== "range" ? parseInt(selectedYear) : undefined}
                    dateRangeStart={selectedYear === "range" ? dateRangeStart : undefined}
                    dateRangeEnd={selectedYear === "range" ? dateRangeEnd : undefined}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
}
