import baseApi from "./base-api";

/**
 * @param {Object} params
 * @param {string} params.search - search text
 * @param {number} params.page
 * @param {number} params.limit
 * @param {string[]} params.organizationIds
 * @param {string[]} params.teamIds
 * @param {string[]} params.userIds
 * @param {import('../typedefs').PlatformFlow} params.platformFlow
 * @param {string} params.dateRangeStart
 * @param {string} params.dateRangeEnd
 */
function getThankyous(params = {}) {
    return baseApi.getWithParams("thank-yous", params);
}

/**
 * @param {string} thankYouId
 */
function getThankYouById(thankYouId) {
    return baseApi.get(`thank-yous/thankyouById/${thankYouId}`);
}

/**
 * @param {Object} params
 * @param {string[]} params.organizationIds
 * @param {string[]} params.teamIds
 * @param {import('../typedefs').PlatformFlow} params.platformFlow
 * @param {string} params.dateRangeStart
 * @param {string} params.dateRangeEnd
 */
function downloadAsCsv(params = {}) {
    return baseApi.getWithParams("thank-yous/download-csv", params);
}

function getDraftThankyous({ pageNumber, pageSize, isArchived } = {}) {
    return baseApi.getWithParams("thank-yous/draft-thankyou", {
        page: pageNumber,
        limit: pageSize,
        isArchived: isArchived
    });
}

function getModerationFailedThankyous({ pageNumber, pageSize } = {}) {
    return baseApi.getWithParams("thank-yous/moderationFailedThankyou", {
        page: pageNumber,
        limit: pageSize
    });
}

function sendMultipleThankyou(data) {
    return baseApi.post("admin/appreciation/send-thank-you/multiple", data);
}

function deleteDraftThankyous({ draftThankyouId, isArchived } = {}) {
    return baseApi.deleteWithParams("thank-yous/draft-thankyou/updateArchivedStatus", {
        draftThankyouId: draftThankyouId,
        isArchived: isArchived
    });
}

function releaseDraftThankyou({ draftThankyouId } = {}) {
    return baseApi.put("thank-yous/draft-thankyou/release", {
        draftThankyouId: draftThankyouId
    });
}

function deleteThankyou(thankyouId) {
    return baseApi.delete(`admin/appreciation/thankyou/${thankyouId}`);
}

function deleteModerationFailedThankyou(thankyouId) {
    return baseApi.delete(`thank-yous/moderationFailedThankyou/${thankyouId}`);
}

const thankYouService = {
    getThankyous: getThankyous,
    getThankYouById: getThankYouById,
    downloadAsCsv: downloadAsCsv,
    getDraftThankyous: getDraftThankyous,
    deleteDraftThankyous: deleteDraftThankyous,
    releaseDraftThankyou: releaseDraftThankyou,
    sendMultipleThankyou: sendMultipleThankyou,
    deleteThankyou: deleteThankyou,
    getModerationFailedThankyous: getModerationFailedThankyous,
    deleteModerationFailedThankyou: deleteModerationFailedThankyou,
};

export default thankYouService;
