import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.min.css';
import { BrowserRouter } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from './common/constants/local-storage.constant';
import { baseApiInterceptors } from './api/base-api.interceptor';

if (localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)) {
  baseApiInterceptors.addAuthHeaderInterceptor();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <BrowserRouter>
      <App />
      <link rel='stylesheet' href='https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css'></link>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/7.0.96/css/materialdesignicons.min.css" integrity="sha512-fXnjLwoVZ01NUqS/7G5kAnhXNXat6v7e3M9PhoMHOTARUMCaf5qNO84r5x9AFf5HDzm3rEZD8sb/n6dZ19SzFA==" crossOrigin="anonymous" referrerPolicy="no-referrer" />
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
    </BrowserRouter>
  </>
);

// reportWebVitals();
