import baseApi from "./base-api";

/**
 * @typedef GetStatsBaseParams
 * @property {string} params.year
 * @property {string[]} params.organizationIds
 * @property {string[]} params.teamIds
 * @property {import('../typedefs').PlatformFlow} params.platformFlow
 * @property {string} params.dateRangeStart
 * @property {string} params.dateRangeEnd
 * @property {boolean} params.useCache
 */

function getGlobalStats() {
    const params = {};

    if (localStorage.getItem('disable-cache') === 'true') {
        params.useCache = "false";
    }

    return baseApi.getWithParams("stats/global-stats", params);
}

/**
 * @param {GetStatsBaseParams} params
 */
function getAppreciationStats(params = {}) {
    if (localStorage.getItem('disable-cache') === 'true') {
        params.useCache = "false";
    }

    return baseApi.getWithParams("stats/appreciation-stats", params);
}

/**
 * @param {GetStatsBaseParams} params
 */
function getAppreciationStatsForCharts(params = {}) {
    return baseApi.getWithParams("stats/appreciation-stats/charts", params);
}

/**
 * @param {GetStatsBaseParams} params
 */
function getEngagementStats(params = {}) {
    if (localStorage.getItem('disable-cache') === 'true') {
        params.useCache = 'false';
    }

    return baseApi.getWithParams("stats/engagement-stats", params);
}

/**
 * @param {string[]} organizationIds
 */
function refreshStatsCache(organizationIds) {
    return baseApi.getWithParams("stats/refresh-cache", {
        organizationIds: organizationIds,
    });
}

const statsService = {
    getGlobalStats: getGlobalStats,
    getAppreciationStats: getAppreciationStats,
    getAppreciationStatsForCharts: getAppreciationStatsForCharts,
    getEngagementStats: getEngagementStats,
    refreshStatsCache: refreshStatsCache,
};

export default statsService;
