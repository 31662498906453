import { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import './Dashboard.css';
import { SidebarLayoutContent } from '../../shared/components/SidebarLayoutContent';
import statsService from '../../Services/stats.service';
import { GlobalStats } from './GlobalStats';
import { useLoaderStore } from '../../stores/loader.store';
import { DashboardStats } from './DashboardStats';
import { DashboardCharts } from './DashboardCharts';
import { Modal } from '../../shared/components/Modal/Modal';
import { DashboardFilters } from './DashboardFilters';
import webadminReportingService from '../../Services/webadmin-reporting.service';
import { downloadExcelFromData, formatDateStrForExcel } from '../../common/utils/excel.util';
import { QUERY_KEYS } from '../../common/query-keys';
import { useGetOrganizations } from '../../hooks/organization/organization.hook';
import { downloadCsvFromData } from '../../common/utils/csv.util';

const DASHBOARD_TABS = Object.freeze({
    STATS: "stats",
    CHARTS: "charts",
});

export function Dashboard() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const queryClient = useQueryClient();
    const [selectedTab, setSelectedTab] = useState(DASHBOARD_TABS.STATS);
    const [filters, setFilters] = useState({
        organizationId: '',
        teamIds: [],
        year: '',
        dateRangeStart: '',
        dateRangeEnd: '',
        platformFlow: '',
    });
    const [showFilters, setShowFilters] = useState(false);

    const { data: statsRes, isFetching: isLoadingStats } = useQuery({
        queryKey: [QUERY_KEYS.APPRECIATION_STATS, {
            year: filters.year !== "range" ? filters.year : undefined,
            organizationId: filters.organizationId,
            teamIds: filters.teamIds,
            platformFlow: filters.platformFlow,
            dateRangeStart: filters.year === "range" ? filters.dateRangeStart : undefined,
            dateRangeEnd: filters.year === "range" ? filters.dateRangeEnd : undefined,
        }],
        queryFn: () => statsService.getAppreciationStats({
            year: filters.year !== "range" ? filters.year : undefined,
            organizationId: filters.organizationId || undefined,
            teamIds: filters.teamIds,
            platformFlow: filters.platformFlow,
            dateRangeStart: filters.year === "range" ? filters.dateRangeStart : undefined,
            dateRangeEnd: filters.year === "range" ? filters.dateRangeEnd : undefined,
        }),
        staleTime: Infinity,
    });
    const stats = statsRes?.data;

    const { data: organizationsData } = useGetOrganizations();

    async function refreshStatsCache() {
        setShowLoader(true);

        try {
            const res = await statsService.refreshStatsCache(filters.organizationId || undefined);

            if (res.status === 200) {
                queryClient.invalidateQueries([QUERY_KEYS.GLOBAL_STATS]);
                queryClient.invalidateQueries([QUERY_KEYS.APPRECIATION_STATS]);
                queryClient.invalidateQueries([QUERY_KEYS.ENGAGEMENT_STATS]);
                return;
            }

            toast.error("Couldn't refresh stats cache");
        } catch (err) {
            toast.error("Couldn't refresh stats cache");
        } finally {
            setShowLoader(false);
        }
    }

    async function downloadOrganizationReport() {
        setShowLoader(true);

        try {
            const res = await webadminReportingService.downloadOrganizationReport({
                organizationId: filters.organizationId,
                teamIds: filters.teamIds,
                dateRangeStart: filters.year === "range" ? filters.dateRangeStart : undefined,
                dateRangeEnd: filters.year === "range" ? filters.dateRangeEnd : undefined,
            });

            const currentDateIsoStr = (new Date()).toISOString();
            let filename = 'HUMAN REPORT';
            if (organizationsData?.results?.length) {
                const organization = organizationsData.results.find((org) => org._id === filters.organizationId);
                if (organization?.abbreviatedName) {
                    filename = filename + ` - ${organization?.abbreviatedName}`;
                }
            }
            filename = filename + ' - Stats & Data';
            filename = filename + ` - ${formatDateStrForExcel(currentDateIsoStr)}`;
            filename = filename + '.xlsx';

            downloadExcelFromData(res.data, filename);
        } catch (err) {
            console.log(err);
            toast.error("Couldn't download report");
        } finally {
            setShowLoader(false);
        }
    }

    async function downloadAnalyticsReport() {
        setShowLoader(true);

        try {
            const res = await webadminReportingService.downloadAnalyticsReport({
                organizationId: filters.organizationId,
                teamIds: filters.teamIds,
                dateRangeStart: filters.year === "range" ? filters.dateRangeStart : undefined,
                dateRangeEnd: filters.year === "range" ? filters.dateRangeEnd : undefined,
            });

            const currentDateIsoStr = (new Date()).toISOString();
            let filename = 'HUMAN REPORT';
            if (organizationsData?.results?.length) {
                const organization = organizationsData.results.find((org) => org._id === filters.organizationId);
                if (organization?.abbreviatedName) {
                    filename = filename + ` - ${organization?.abbreviatedName}`;
                }
            }
            filename = filename + ' - Analytics Data';
            filename = filename + ` - ${formatDateStrForExcel(currentDateIsoStr)}`;
            filename = filename + '.csv';

            downloadCsvFromData(res, filename);
        } catch (err) {
            console.log(err);
            toast.error("Couldn't download report");
        } finally {
            setShowLoader(false);
        }
    }

    return (
        <SidebarLayoutContent
            headerTitle='Patient & Staff Experience Measures'
            showHeaderBreadcrumbs={false}
        >
            <GlobalStats />

            <div className='d-flex mb-4'>
                <div className='single-selection-button-group mr-2'>
                    <button
                        type='button'
                        className={`${selectedTab === DASHBOARD_TABS.STATS ? 'active' : ''}`}
                        onClick={() => setSelectedTab(DASHBOARD_TABS.STATS)}
                    >Stats</button>
                    <button
                        type='button'
                        className={`${selectedTab === DASHBOARD_TABS.CHARTS ? 'active' : ''}`}
                        onClick={() => setSelectedTab(DASHBOARD_TABS.CHARTS)}
                    >Charts</button>
                </div>

                <button type='button' className='custom-filter-icon-btn' onClick={() => setShowFilters(true)}>
                    <i className="fi fi-rr-settings-sliders"></i>
                </button>
                <button type='button' className='custom-filter-icon-btn ml-2' onClick={refreshStatsCache}>
                    <i className="fi fi-rr-refresh"></i>
                </button>
                {filters.organizationId && (
                    <>
                        <button type='button' className="custom-filter-icon-btn ml-2" onClick={downloadOrganizationReport} title='Summary Report'>
                            <i className="fi fi-rr-download"></i>
                        </button>
                        <button type='button' className="custom-filter-icon-btn ml-2" onClick={downloadAnalyticsReport} title='Analytics Report'>
                            <i className="fi fi-rr-chart-histogram"></i>
                        </button>
                    </>
                )}
            </div>

            {selectedTab === DASHBOARD_TABS.STATS && (
                <DashboardStats
                    stats={stats}
                    filters={filters}
                    isLoading={isLoadingStats}
                />
            )}

            {selectedTab === DASHBOARD_TABS.CHARTS && (
                <DashboardCharts
                    filters={filters}
                    selectedYear={filters.year}
                    dateRangeStart={filters.dateRangeStart}
                    dateRangeEnd={filters.dateRangeEnd}
                />
            )}

            <Modal
                show={showFilters}
                onClose={() => setShowFilters(false)}
            >
                <DashboardFilters
                    filters={filters}
                    onApplyFilters={(newFilters) => {
                        setFilters(newFilters);
                        setShowFilters(false);
                    }}
                />
            </Modal>
        </SidebarLayoutContent>
    );
}
