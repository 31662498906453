import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Header from '../NavMenuBar/Header';
import giftService from '../../Services/gift.service';
import { ConfirmationModal } from '../../shared/components/Modal/ConfirmationModal';
import cloudUpload from "../../assets/Images/cloud-upload.png";
import { GiftProcessStateValues } from '../../features/gift/models/gift-process-state.enum';
import { formatDateForTable } from '../../common/utils/date.util';

export function ViewGiftDetails() {
    const [searchParams] = useSearchParams();
    const giftId = searchParams.get('id');
    const [giftDetails, setGiftDetails] = useState();
    const [giftProcessState, setGiftProcessState] = useState("");
    const [giftAdminNotes, setGiftAdminNotes] = useState("");
    const [voucherLink, setVoucherLink] = useState("");
    const [giftAmount, setGiftAmount] = useState("");
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const [showReSendEmailConfirmation, setReSendEmailConfirmation] = useState(false);

    const senderDetails = giftDetails ? getGiftSenderDetails(giftDetails) : null;

    useEffect(() => {
        getGiftDetails();
    }, []);

    useEffect(() => {
        if (voucherLink) {
            setGiftProcessState('Voucher Issued');
        }
    }, [voucherLink]);

    async function getGiftDetails() {
        try {
            const response = await giftService.getGiftById(giftId);

            if (response?.status === 200) {
                const giftData = response.data;
                setGiftDetails(giftData);

                if (giftData?.processState) {
                    setGiftProcessState(giftData?.processState);
                    setGiftAdminNotes(giftData?.giftAdminNotes);
                }

                if (giftData?.voucherLink) {
                    setVoucherLink(giftData?.voucherLink);
                    setDisableSaveButton(true);
                }

                let data = giftData.giftCard?.priceWithCurrency?.find((data) => data.value == giftData.payment[0]?.currency);
                setGiftAmount(data?.value + data?.price[0].toString());

                return;
            }

            toast.error("Couldn't get gift details");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't get gift details");
        }
    }

    function resendVoucher(e) {
        e.preventDefault();

        if (!giftProcessState) {
            toast.warning("Please select a state");
            return;
        }

        if (giftProcessState == 'Voucher Issued' && !voucherLink) {
            toast.warning("Please add voucher link");
            return;
        }

        if (giftDetails.isVoucherSent) {
            setReSendEmailConfirmation(true);
            return;
        }

        resendVoucherEmail();
    }

    function handleResendVoucher() {
        resendVoucherEmail();
        setReSendEmailConfirmation(false);
    }

    async function resendVoucherEmail() {
        try {
            const response = await giftService.resendVoucherEmailApi(giftId, giftProcessState, giftAdminNotes, voucherLink, giftAmount);
            getGiftDetails();
            if (response.status === 200) {
                toast.success("Successfully send voucher email");
                if (giftProcessState == 'Voucher Issued') {

                    setDisableSaveButton(true);
                }
                return;
            }

            toast.error("Couldn't send voucher email");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't send voucher email");
        }
    }

    function onSubmit(e) {
        e.preventDefault();

        if (!giftProcessState) {
            toast.warning("Please select a state");
            return;
        }

        if (giftProcessState == 'Voucher Issued' && !voucherLink) {
            toast.warning("Please add voucher link");
            return;
        }

        updateGiftVoucherData();
    }

    async function updateGiftVoucherData() {
        try {
            const response = await giftService.updateGiftVoucher(giftId, giftProcessState, giftAdminNotes, voucherLink, giftAmount);

            if (response.status === 200) {
                toast.success("Successfully updated gift");
                if (giftProcessState == 'Voucher Issued') {

                    setDisableSaveButton(true);
                }
                return;
            }

            toast.error("Couldn't update gift");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't update gift");
        }
    }


    async function updateGiftProcessState(processState) {
        try {
            const response = await giftService.updateGiftProcessState(giftId, processState, giftAdminNotes);

            if (response.status === 200) {
                toast.success("Successfully updated gift");
                return;
            }

            toast.error("Couldn't update gift");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't update gift");
        }
    }

    function getGiftSenderDetails(gift) {
        if (gift.platform === 'mobile' && gift.createdBy) {
            return {
                firstName: gift.createdBy.firstName,
                email: gift.createdBy.email,
                phoneNumber: gift.createdBy.phoneNumber,
            };
        }

        if (gift.platform === 'web' && gift.createdUserData) {
            return {
                firstName: gift.createdUserData.firstName,
                email: gift.createdUserData.email,
                phoneNumber: gift.createdUserData.phoneNumber,
            };
        }
    }

    return (
        <main className="page-content bg-light">
            <Header title1="Gift Details" title="Gift List" main_title="Gift" title_link="gift-list" />

            <div className="container-fluid">
                <div className="layout-specing">
                    <div>
                        <h4 className="mb-4">Gift Details</h4>
                    </div>

                    {giftDetails && (
                        <div class="col-md-12">

                            <div class="mb-4">
                                <label class="cstm-label">Message</label>
                                <textarea
                                    name="message"
                                    id="message"
                                    readOnly
                                    rows="2"
                                    value={giftDetails.message}
                                    type="text"
                                    class="cstm-input cstm-textarea"
                                />
                            </div>


                            <div class="mb-4">
                                <label class="cstm-label">Reply</label>
                                <textarea
                                    name="message"
                                    id="message"
                                    rows="2"
                                    readOnly
                                    value={giftDetails.replies ? giftDetails.replies[0]?.message : null}
                                    type="text"
                                    class="cstm-input cstm-textarea"
                                />
                            </div>

                            <div class="mb-4">
                                <label style={{ color: 'red' }} class="cstm-label">Auto Reply</label>
                                <textarea
                                    name="message"
                                    id="message"
                                    readOnly
                                    rows="2"
                                    value={giftDetails.autoReply ? giftDetails.autoReply[0]?.replyMessage : null}
                                    type="text"
                                    class="cstm-input cstm-textarea"
                                />
                            </div>

                            {/* Sender Details */}
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="cstm-label">Sender Details</label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="mb-3">
                                        <label class="cstm-label">Sender Name</label>
                                        <input
                                            name="senderName"
                                            id="senderName"
                                            value={senderDetails?.firstName}
                                            readOnly
                                            type="text"
                                            class="cstm-input"
                                            placeholder="Enter gift name"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="mb-3">
                                        <label class="cstm-label">Sender Email</label>
                                        <input
                                            name="senderEmail"
                                            id="senderEmail"
                                            value={senderDetails?.email}
                                            readOnly
                                            type="text"
                                            class="cstm-input"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="mb-3">
                                        <label class="cstm-label">Sender Phone</label>
                                        <input
                                            name="senderPhone"
                                            id="senderPhone"
                                            readOnly
                                            value={senderDetails?.phoneNumber}
                                            type="text"
                                            class="cstm-input"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Receiver Details */}
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="cstm-label">Receiver Details</label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="mb-4">
                                        <label class="cstm-label">Receiver Name</label>
                                        <input
                                            name="senderName"
                                            id="senderName"
                                            readOnly
                                            value={giftDetails.users ? giftDetails.users[0]?.firstName : null}
                                            type="text"
                                            class="cstm-input"
                                            placeholder="Enter gift name"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="mb-4">
                                        <label class="cstm-label">Receiver Email</label>
                                        <input
                                            name="senderEmail"
                                            id="senderEmail"
                                            readOnly
                                            value={giftDetails.users ? giftDetails.users[0]?.email : null}
                                            type="text"
                                            class="cstm-input"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="mb-4">
                                        <label class="cstm-label">Receiver Phone</label>
                                        <input
                                            name="senderPhone"
                                            id="senderPhone"
                                            readOnly
                                            value={giftDetails.users ? giftDetails.users[0]?.phoneNumber : null}
                                            type="text"
                                            class="cstm-input"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="mb-4">
                                        <label class="cstm-label">Receiver Organization</label>
                                        <input
                                            name="senderPhone"
                                            id="senderPhone"
                                            readOnly
                                            value={giftDetails?.users && giftDetails?.users[0]?.companyData.length ? giftDetails?.users[0]?.companyData.map((list) => list.name).join(",") : null}
                                            type="text"
                                            class="cstm-input"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Gift Details */}
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="cstm-label">Gift Details</label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="mb-4">
                                        <label class="cstm-label">Gift Type</label>
                                        <input
                                            name="senderName"
                                            id="senderName"
                                            readOnly
                                            value={giftDetails.giftCard?.type}
                                            type="text"
                                            class="cstm-input"
                                            placeholder="Enter gift name"
                                        />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="mb-4">
                                        <label class="cstm-label">Gift Partner</label>
                                        <input
                                            name="senderName"
                                            id="senderName"
                                            readOnly
                                            value=''
                                            type="text"
                                            class="cstm-input"
                                        />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="mb-4">
                                        <label class="cstm-label">Gift Type</label>
                                        <input
                                            name="senderName"
                                            id="senderName"
                                            readOnly
                                            value=''
                                            type="text"
                                            class="cstm-input"
                                        />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="mb-4">
                                        <label class="cstm-label">Gift Amount</label>
                                        <input
                                            name="senderName"
                                            id="senderName"
                                            readOnly
                                            value={giftAmount}
                                            type="text"
                                            class="cstm-input"
                                            placeholder="Gift Amount"
                                        />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="mb-4">
                                        <label class="cstm-label">Gift Date</label>
                                        <input
                                            name="senderName"
                                            id="senderName"
                                            readOnly
                                            value={giftDetails.createdAt ? formatDateForTable(giftDetails.createdAt) : null}
                                            type="text"
                                            class="cstm-input"
                                            placeholder="Enter gift name"
                                        />
                                    </div>
                                </div>

                            </div>

                            <form onSubmit={onSubmit}>
                                <div class="row">
                                    {/* Gift Issuance */}
                                    <div class="col-md-4">
                                        <div class="mb-4">
                                            <label class="cstm-label">Gift Issuance Status</label>
                                            <select
                                                className="form-select cstm-category"
                                                value={giftProcessState}
                                                onChange={(e) => setGiftProcessState(e.target.value)}
                                            >
                                                <option value="" disabled>Select State</option>

                                                {GiftProcessStateValues.map((processState) => (
                                                    <option key={processState} value={processState}>{processState}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="mb-4">
                                            <label class="cstm-label">Voucher Link</label>
                                            <input
                                                name="senderName"
                                                id="senderName"
                                                value={voucherLink}
                                                onChange={(e) => setVoucherLink(e.target.value)}
                                                type="text"
                                                class="cstm-input"
                                                placeholder="Copy/Paste Voucher Link"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    {/* Comment */}
                                    <div className="mb-4 col-md-7">
                                        <label className="cstm-label">Comment</label>
                                        <textarea
                                            rows="5"
                                            value={giftAdminNotes}
                                            onChange={(e) => setGiftAdminNotes(e.target.value)}
                                            className="cstm-textarea"
                                            placeholder="Comment"
                                        ></textarea>
                                    </div>
                                </div>
                                <div>
                                    <div className="submit-btnMain cstm-loader mr-2">
                                        <button type="button" disabled={!disableSaveButton} onClick={resendVoucher} className={"btn-submit-login-form cstm-submitbutton btn-disable " + (!disableSaveButton ? 'cstm-submitbutton-disable' : 'cstm-submitbutton')}>Resend/Send Voucher</button>
                                    </div>
                                    <div className="submit-btnMain cstm-loader">
                                        <button type="submit" disabled={disableSaveButton} className={"btn-submit-login-form cstm-submitbutton btn-disable " + (disableSaveButton ? 'cstm-submitbutton-disable' : 'cstm-submitbutton')}>Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}


                    {/* {giftDetails && (
                        <div className='card border-0 shadow rounded mb-4 p-2'>
                            <div>
                                <p>Message: {giftDetails.message}</p>
                                <p style={{ color: 'red' }}>Auto Reply: {giftDetails.autoReply ? giftDetails.autoReply[0]?.replyMessage : null}</p>
                                <p>Reply: {giftDetails.replies ? giftDetails.replies[0]?.message : null}</p>
                                <div>
                                    <p>Sender Name: {senderDetails?.firstName}</p>
                                    <p>Sender Email: {senderDetails?.email}</p>
                                    <p>Sender Phone: {senderDetails?.phoneNumber}</p>
                                </div>
                                <div>
                                    <p>Receiver Name: {giftDetails.users ? giftDetails.users[0]?.firstName : null}</p>
                                    <p>Receiver Organization: {giftDetails?.users && giftDetails?.users[0]?.companyData.length ? giftDetails?.users[0]?.companyData.map((list) => list.name).join(",") : null}</p>
                                    <p>Receiver Email: {giftDetails.users ? giftDetails.users[0]?.email : null}</p>
                                    <p>Receiver Phone: {giftDetails.users ? giftDetails.users[0]?.phoneNumber : null}</p>
                                </div>
                                <p>Type: {giftDetails.giftCard?.type}</p>
                                <p>Platform: {giftDetails.flow === "1" ? "Patient Experience" : "Staff Experience"}</p>
                                <p>Date: {giftDetails.createdAt ? formatDateForTable(giftDetails.createdAt) : null}</p>
                            </div>

                            <form onSubmit={onSubmit}>
                                <div className="mb-4 col-md-7">
                                    <label className="cstm-label">Gift Process State</label>
                                    <select
                                        className="form-select cstm-category"
                                        value={giftProcessState}
                                        onChange={(e) => setGiftProcessState(e.target.value)}
                                    >
                                        <option value="" disabled>Select State</option>

                                        {GiftProcessStateValues.map((processState) => (
                                            <option key={processState} value={processState}>{processState}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="mb-4 col-md-7">
                                    <label className="cstm-label">Notes</label>
                                    <textarea
                                        rows="5"
                                        value={giftAdminNotes}
                                        onChange={(e) => setGiftAdminNotes(e.target.value)}
                                        className="cstm-textarea"
                                        placeholder="Note"
                                    ></textarea>
                                </div>

                                <div className="submit-btnMain cstm-loader">
                                    <button type="submit" className="btn-submit-login-form cstm-submitbutton btn-disable">Save</button>
                                </div>
                            </form>
                        </div>
                    )} */}
                </div>

                {showReSendEmailConfirmation &&
                    <ConfirmationModal
                        text='Are you sure that you want to resend the voucher?'
                        confirmBtnText='Resend'
                        imgSrc={cloudUpload}
                        onConfirm={handleResendVoucher}
                        onCancel={() => setReSendEmailConfirmation(false)}
                    />}
            </div>
        </main >
    );
}
