import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import nonUserService from "../../../Services/non-user.service";
import thankYouService from "../../../Services/thank-you.service";
import { formatDateForTable } from '../../../common/utils/date.util';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants/pagination.constant';
import { SidebarLayoutContent } from '../../../shared/components/SidebarLayoutContent';
import { Pagination } from '../../shared/Pagination';
import { useLoaderStore } from '../../../stores/loader.store';
import { ConfirmationModal } from '../../../shared/components/Modal/ConfirmationModal';
import { createSearchParamsFromListState, getListStateFromSearchParams } from '../../../utils/list-state.util';

const NO_CONTACT_ACTION = Object.freeze({
    DELETE: "DELETE",
    RELEASE: "RELEASE"
});

export function NonUserNoContactThankYouList() {
    const location = useLocation();
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [searchParams, setSearchParams] = useSearchParams();
    const [resultsCount, setResultsCount] = useState(0);
    const state = useMemo(() => getThankYouListStateFromSearchParams(searchParams), [searchParams]);
    const pagination = { pageNumber: state.pageNumber, pageSize: DEFAULT_PAGE_SIZE };
    const [thankYous, setThankYous] = useState([]);
    const [selectedThankyou, setSelectedThankyou] = useState({});
    const [searchText, setSearchText] = useState();

    /** @type {[{ team: any, action: string }, React.Dispatch<{ team: any, action: string }>]} */
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        getNoContactThankyous();
    }, []);

    useEffect(() => {
        getNoContactThankyous();
    }, [searchParams]);

    async function getNoContactThankyous() {
        console.log(pagination.pageNumber);
        try {
            const res = await nonUserService.getNoContactThankyous({
                page: pagination.pageNumber,
                limit: pagination.pageSize,
            });

            if (res.status === 200) {
                setThankYous(res.data.thankyou_data);
                setResultsCount(res.data.state?.data_count);
                // toast.success("successfully fetched thankyous");
                return;
            }

            toast.error("Couldn't get thankyous");
        } catch (err) {
            if (err.error) {
                toast.error(err.error);
                return;
            }
            toast.error("Couldn't get thankyous");
        } finally {
            setShowLoader(false);
        }
    }

    async function handleReleaseThankyou() {
        try {

            setShowLoader(true);
            const releaseThankyou = selectedRow.thankYou;
            const res = await nonUserService.releaseNoContactThankyou(releaseThankyou._id);

            if (res.status === 200) {
                setThankYous((current) =>
                    current.filter((data) => data._id !== releaseThankyou._id)
                );
                toast.success("successfully released thankyou");
                return;
            }

            toast.error("Couldn't release thank you");
        } catch (err) {
            if (err.error) {
                toast.error(err.error);
                return;
            }
            toast.error("Couldn't release thank you");
        } finally {
            setShowLoader(false);
            setSelectedRow(null)
        }
    }

    async function handleDeleteThankyou() {
        setShowLoader(true);
        try {
            const deleteThankyou = selectedRow.thankYou;
            const res = await nonUserService.deleteNonUserThankyou(deleteThankyou._id);

            if (res.status === 200) {
                toast.success("Successfully deleted thank you");
                setThankYous((thankYou) => thankYou.filter((t) => t._id !== deleteThankyou._id));
                return;
            }

            toast.error("Couldn't delete thank you");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't delete thank you");
        } finally {
            setSelectedRow(null);
            setShowLoader(false);
        }
    }

    async function handleSendToTeam() {
        setShowLoader(true);
        try {
            let data = {
                "appreciationType": "nonUserThankyou",
                "type": "team",
                "teamId": selectedThankyou.team[0]._id,
                "appreciationId": selectedThankyou._id
            }
            const res = await thankYouService.sendMultipleThankyou(data);

            if (res.status == 200) {
                toast.success("Successfully sent the thank yous to team");
                return;
            }
            toast.error("Couldn't send thank yous to team");
        } catch (err) {
            toast.error("Couldn't send thank yous to team");
        }
        finally {
            setShowLoader(false);
            handleSendTeamCancel();
        }
    }

    const handleSendTeamCancel = () => {
        setSelectedThankyou(null);
        document.querySelector('.modal-backdrop').remove();
        document.querySelector('#sendTeamConfirmationThankyou').classList.remove('show');
    }

    function onPaginationChange(pageNumber) {
        setSearchParams(createThankYouListSearchParams({}, '', pageNumber));
    }

    return (
        <SidebarLayoutContent headerTitle='Non User - No contact thank you'>
            <div className="card border-0 shadow rounded">
                <div className='d-flex justify-content-end align-items-center p-2'>
                    {/* <div className="mr-2">
                        <i className="fi fi-rr-search cstm-search-ro"></i>
                        <input
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            type="text"
                            className="cstm-input-seacrh"
                            placeholder="Search"
                        />
                    </div> */}
                </div>

                <div className="overflow-auto">
                    <table className="table mb-0 table-center">
                        <thead>
                            <tr className="border-top">
                                <th className="border-bottom w-4 cstm-userheading">Message</th>
                                <th className="border-bottom w-4 cstm-userheading">From</th>
                                <th className="border-bottom w-4 cstm-userheading">To</th>
                                <th className="border-bottom w-4 cstm-userheading">Organization</th>
                                <th className="border-bottom w-4 cstm-userheading">Team</th>
                                <th className="border-bottom w-4 cstm-userheading">Created Date</th>
                                <th className="border-bottom w-4 cstm-userheading">Actions</th>

                            </tr>
                        </thead>

                        <tbody>
                            {thankYous?.map((thankyou, index) => (
                                <tr
                                    key={thankyou._id}
                                    className="cstm-Tabledesign cstm-usertable-design"
                                >
                                    <td>{thankyou.message}</td>
                                    <td>{thankyou.fromUserData?.firstName}</td>
                                    <td>{thankyou.toStaffName}</td>
                                    <td>{thankyou.organization[0]?.name}</td>
                                    <td>{thankyou.team[0]?.name}</td>
                                    <td>{formatDateForTable(thankyou.createdAt)}</td>

                                    <td>
                                        {thankyou.isActive && 'released'}
                                        {!thankyou.isActive &&
                                            <div>
                                                <button
                                                    type='button'
                                                    className="cstm-eye"
                                                    onClick={() => setSelectedRow({ thankYou: thankyou, action: NO_CONTACT_ACTION.RELEASE })}
                                                    class="cstm-delete mrn-rt"
                                                >
                                                    <i className="fi fi-rr-cloud-upload"></i>

                                                </button>

                                                <Link
                                                    to={""}
                                                    onClick={() => setSelectedRow({ thankYou: thankyou, action: NO_CONTACT_ACTION.DELETE })}
                                                    class="cstm-delete mrn-rt"
                                                >
                                                    <i class="fi fi-rr-trash"></i>
                                                </Link>

                                            </div>}

                                        {/* share */}
                                        {thankyou.appreciationShareDetails.length > 0 && 'Shared'}
                                        {!thankyou.appreciationShareDetails.length > 0 &&
                                            <span>
                                                <Link
                                                    to={`/user-thankyou-share?id=${thankyou._id}&type=nonUserThankyou`}
                                                    className="cstm-eye"
                                                    state={{ breadcrumbPath: location.pathname + location.search }}
                                                >
                                                    <button
                                                        type='button'
                                                        onClick={() => setSelectedThankyou(thankyou)}
                                                        className="cstm-delete mrn-rt"
                                                    >
                                                        <i className="fi fi-rr-user"></i>
                                                    </button>
                                                </Link>

                                                {thankyou?.team && thankyou?.team[0] &&
                                                    <button
                                                        type='button'
                                                        onClick={() => setSelectedThankyou(thankyou)}
                                                        className="cstm-delete mrn-rt"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#sendTeamConfirmationThankyou"
                                                    >
                                                        <i className="fi fi-rr-users-alt"></i>
                                                    </button>}
                                            </span>}
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>

                    {!thankYous?.length && (
                        <div className="NoRecord-cstm">No records found!</div>
                    )}
                </div>
            </div>

            <Pagination
                totalRows={resultsCount}
                currentPage={pagination.pageNumber}
                rowsPerPage={pagination.pageSize}
                onPaginationChange={onPaginationChange}
            />

            {(selectedRow && selectedRow.action === NO_CONTACT_ACTION.DELETE) && (
                <ConfirmationModal
                    text='Are you sure you want to delete this thank you?'
                    confirmBtnText='Delete'
                    type="delete"
                    onConfirm={handleDeleteThankyou}
                    onCancel={() => setSelectedRow(null)}
                />
            )}

            {(selectedRow && selectedRow.action === NO_CONTACT_ACTION.RELEASE) && (
                <ConfirmationModal
                    text='Are you sure you want to release this thank you?'
                    confirmBtnText='Release'
                    type="delete"
                    onConfirm={handleReleaseThankyou}
                    onCancel={() => setSelectedRow(null)}
                />
            )}

            <div
                className="modal fade"
                id="sendTeamConfirmationThankyou"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body py-5">
                            <div className="text-center">
                                <div className="d-flex justify-content-center">
                                    <img src="" alt='' />
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ display: "none" }}>
                                </button>
                                <h4 className="mt-4">
                                    Are you sure want to share this message to
                                    <br /> {selectedThankyou?.teams ? selectedThankyou?.teams[0]?.name : ''} team
                                </h4>
                                <div className="mt-4">
                                    <button
                                        className="mr-3 cstm-btn cstn-discard"
                                        onClick={handleSendToTeam}
                                    >
                                        Share
                                    </button>
                                    <button className="cstm-btn2 cstn-discard" onClick={handleSendTeamCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </SidebarLayoutContent>
    );
}

function createThankYouListSearchParams(filters, searchText, pageNumber) {
    return createSearchParamsFromListState(filters, searchText, pageNumber);
}

function getThankYouListStateFromSearchParams(searchParams) {
    return getListStateFromSearchParams(searchParams);
}
