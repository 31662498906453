const MONTH_ABBREVIATIONS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export function downloadExcelFromData(excelData, excelName) {
    const href = URL.createObjectURL(new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', excelName || 'data.xlsx');
    link.click();

    link.remove();
    URL.revokeObjectURL(href);
}

/**
 * @param {string} dateStr
 */
export function formatDateStrForExcel(dateStr) {
    const dateObj = new Date(dateStr);

    let day = dateObj.getDate();

    // format the day to be two digits (2 will be 02)
    if (day < 10) {
        day = '0' + day;
    }

    const monthIndex = dateObj.getMonth();
    const month = MONTH_ABBREVIATIONS[monthIndex];
    const year = dateObj.getFullYear();

    const formattedDate = `${day} ${month} ${year.toString().slice(-2)}`;

    return formattedDate;
}
