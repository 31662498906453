import { MONTHS } from "../common/constants/date.constant";

/**
 * @param {number} minYear - oldest year for the chart (ex: 2022)
 * @param {number} minMonth - oldest month for chart of "minYear" (ex: 10 means october of 2022)
 * @param {number} maxYear - latest year for chart (ex: 2023)
 * @param {number} maxMonth - latest month for chart of "maxYear" (ex: 6 means june of 2023)
 */
export function generateChartLabels(minYear, minMonth, maxYear, maxMonth) {
    const chartLabels = [];

    for (let year = minYear; year <= maxYear; year++) {
        let startMonth = 1;
        let endMonth = 12;

        if (year === minYear) {
            startMonth = minMonth;
        }

        if (year === maxYear) {
            endMonth = maxMonth;
        }

        for (let month = startMonth; month <= endMonth; month++) {
            chartLabels.push(`${year} ${MONTHS[month - 1]}`);
        }
    }

    return chartLabels;
}

/**
 * @param {object} counts
 * @param {boolean} shouldAccumulateData
 * @param {number} minYear - oldest year for the chart (ex: 2022)
 * @param {number} minMonth - oldest month for chart of "minYear" (ex: 10 means october of 2022)
 * @param {number} maxYear - latest year for chart (ex: 2023)
 * @param {number} maxMonth - latest month for chart of "maxYear" (ex: 6 means june of 2023)
 */
export function generateChartData(counts, shouldAccumulateData, minYear, minMonth, maxYear, maxMonth) {
    const data = [];
    let accumulatedTotal = 0;

    for (let year = minYear; year <= maxYear; year++) {
        let startMonth = 1;
        let endMonth = 12;

        if (year === minYear) {
            startMonth = minMonth;
        }

        if (year === maxYear) {
            endMonth = maxMonth;
        }

        for (let month = startMonth; month <= endMonth; month++) {
            const monthName = MONTHS[month - 1].toLocaleLowerCase();
            const count = counts[year][monthName] || 0;

            if (shouldAccumulateData) {
                accumulatedTotal += count;
                data.push(accumulatedTotal);
            } else {
                data.push(count);
            }
        }
    }

    return data;
}
