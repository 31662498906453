import { axiosBaseApiClient } from "../api/axios-client";
import baseApi from "./base-api";

// TODO: remove all reporting code when new webadmin is live

/**
 * @param {Object} params
 * @param {string} params.organizationId
 * @param {string[]} params.teamIds
 * @param {string} params.dateRangeStart
 * @param {string} params.dateRangeEnd
 */
function downloadOrganizationReport(params = {}) {
    // return axiosBaseApiClient.get("webadmin-reporting/download-organization-report", {
    //     params: params,
    //     responseType: "arraybuffer",
    // });
    return axiosBaseApiClient.get("v2/webadmin/reporting/download-organization-data-report", {
        params: params,
        responseType: "arraybuffer",
    });
}

/**
 * @param {Object} params
 * @param {string[]} params.organizationIds
 * @param {string[]} params.teamIds
 * @param {string} params.dateRangeStart
 * @param {string} params.dateRangeEnd
 */
function downloadAnalyticsReport(params = {}) {
    // return baseApi.getWithParams("webadmin-reporting/download-analytics-report", params);
    return baseApi.getWithParams("v2/webadmin/reporting/download-analytics-report", params);
}

const webadminReportingService = {
    downloadOrganizationReport: downloadOrganizationReport,
    downloadAnalyticsReport: downloadAnalyticsReport,
};

export default webadminReportingService;
