import { useEffect, useState } from 'react';
import { Chart, CategoryScale, LinearScale, Tooltip, BarElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { STATS_START_DETAILS } from '../../common/constants/date.constant';
import { generateChartData, generateChartLabels } from '../../utils/chart.util';

Chart.register(CategoryScale, BarElement, LinearScale, Tooltip);

const JOIN_DATE_CATEGORY = {
    'users': 'users',
    'teams': 'teams',
};

const currentDate = new Date();

export function UptakeChart({ stats, year, dateRangeStart, dateRangeEnd, isLoading }) {
    const [selectedJoinDateCategory, setSelectedJoinDateCategory] = useState(JOIN_DATE_CATEGORY.users);
    const [chartLabels, setChartLabels] = useState([]);
    const [chartDatasets, setChartDatasets] = useState([]);
    const [shouldAccumulateData, setShouldAccumulateData] = useState(true);

    useEffect(() => {
        if (stats) {
            generateChart();
        }
    }, [stats, selectedJoinDateCategory, shouldAccumulateData]);

    function generateChart() {
        let data = [];
        let counts = {};

        const userCountsByMonth = generateCountsByMonthFromCreatedAt(stats.userData);
        const teamCountsByMonth = generateCountsByMonthFromCreatedAt(stats.teamData);

        switch (selectedJoinDateCategory) {
            case JOIN_DATE_CATEGORY.users: {
                counts = userCountsByMonth;
                break;
            }
            case JOIN_DATE_CATEGORY.teams: {
                counts = teamCountsByMonth;
                break;
            }
            default:
                break;
        }

        if (year) {
            const startMonth = year === STATS_START_DETAILS.YEAR ? STATS_START_DETAILS.MONTH : 1;
            const endMonth = year === currentDate.getFullYear() ? currentDate.getMonth() + 1 : 12;
            const generatedChartLabels = generateChartLabels(year, startMonth, year, endMonth);
            setChartLabels(generatedChartLabels);
            data = generateChartData(counts, shouldAccumulateData, year, startMonth, year, endMonth);
        } else if (dateRangeStart && dateRangeEnd) {
            const startDate = new Date(dateRangeStart);
            const endDate = new Date(dateRangeEnd);
            const generatedChartLabels = generateChartLabels(startDate.getFullYear(), startDate.getMonth() + 1, endDate.getFullYear(), endDate.getMonth() + 1);
            setChartLabels(generatedChartLabels);
            data = generateChartData(counts, shouldAccumulateData, startDate.getFullYear(), startDate.getMonth() + 1, endDate.getFullYear(), endDate.getMonth() + 1);
        } else {
            const generatedChartLabels = generateChartLabels(STATS_START_DETAILS.YEAR, STATS_START_DETAILS.MONTH, currentDate.getFullYear(), currentDate.getMonth() + 1);
            setChartLabels(generatedChartLabels);
            data = generateChartData(counts, shouldAccumulateData, STATS_START_DETAILS.YEAR, STATS_START_DETAILS.MONTH, currentDate.getFullYear(), currentDate.getMonth() + 1);
        }

        setChartDatasets([
            {
                label: 'Count',
                data: data,
                backgroundColor: '#00CCCC',
                borderColor: '#00CCCC',
            }
        ]);
    }

    return (
        <div className={`card shadow border-0 p-3 ${isLoading ? 'custom-skeleton-loader-full' : ''}`}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="align-items-center mb-0">Uptake</h5>

                <div>
                    <input
                        type="checkbox"
                        className="cstm-checkbox"
                        onChange={(e) => setShouldAccumulateData(e.target.checked)}
                        checked={shouldAccumulateData}
                    />
                    <span className="checkmark-content">Accumulate</span>
                </div>
            </div>

            <div className='d-flex justify-content-end'>
                <div className="form-check mr-2">
                    <input
                        className="form-check-input"
                        type="radio"
                        id="category-users"
                        name='join-date-category'
                        value={JOIN_DATE_CATEGORY.users}
                        onChange={(e) => setSelectedJoinDateCategory(e.target.value)}
                        checked={selectedJoinDateCategory === JOIN_DATE_CATEGORY.users}
                    />
                    <label className="form-check-label" htmlFor="category-users">Users</label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        id="category-teams"
                        name='join-date-category'
                        value={JOIN_DATE_CATEGORY.teams}
                        onChange={(e) => setSelectedJoinDateCategory(e.target.value)}
                        checked={selectedJoinDateCategory === JOIN_DATE_CATEGORY.teams}
                    />
                    <label className="form-check-label" htmlFor="category-teams">Teams</label>
                </div>
            </div>

            <div>
                <Bar
                    data={{
                        labels: chartLabels,
                        datasets: chartDatasets,
                    }}
                    options={{
                        plugins: {
                            tooltip: {
                                enabled: true,
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
}

const START_YEAR = 2022;
const currentYear = currentDate.getFullYear();
const YEARS = [];
const MONTHS = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

for (let i = START_YEAR; i <= currentYear; i++) {
    YEARS.push(i);
}

function generateCountsByMonthFromCreatedAt(data = []) {
    const counts = {};
    const initialCountByMonths = {};

    MONTHS.forEach((month) => {
        initialCountByMonths[month] = 0;
    });

    YEARS.forEach((year) => {
        counts[year] = { ...initialCountByMonths };
    });

    data.forEach((item) => {
        const createdDate = new Date(item.createdAt);
        const year = createdDate.getFullYear();
        const month = createdDate.getMonth();

        const monthName = MONTHS[month];

        counts[year][monthName]++;
    });

    return counts;
}
