import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/Images/logo.svg";

const navItems = [
    {
        label: 'Dashboard',
        route: 'dashboard',
        iconClass: 'fi fi-rr-home',
    },
    {
        label: 'Users',
        iconClass: 'fi fi-rr-user',
        subNavItems: [
            {
                label: 'Users',
                route: 'user',
            },
            {
                label: 'Partial Sign ups',
                route: 'partially-registered-users',
            },
            {
                label: 'Team Join Requests',
                route: 'team-join-request',
            },
            {
                label: 'Pending Email Verifications',
                route: 'pending-email-verification-users',
            },
            {
                label: 'Send SMS',
                route: 'send-sms-user',
            }
        ],
    },
    {
        label: 'Organization',
        route: 'organization',
        iconClass: 'fi fi-rr-users-alt',
    },
    {
        label: 'Thank you list',
        route: 'thank-you-list',
        iconClass: 'fi fi-rr-gift',
    },
    {
        label: 'Gift list',
        route: 'gift-list',
        iconClass: 'fi fi-rr-gift',
    },
    {
        label: 'Moderation',
        iconClass: 'fi fi-rr-delete-document',
        subNavItems: [
            {
                label: 'No Sender Contact Details',
                route: 'no-contact-thankyou-list',
            },
            {
                label: 'Failed Thank you',
                route: 'moderation-failed-thankyou-list',
            },
            {
                label: 'Failed Gift',
                route: 'moderation-failed-gift-list',
            },
            {
                label: 'User Reported',
                route: 'reported-messages',
            },
        ]
    },
    {
        label: 'Unregistered Messages',
        iconClass: 'fi fi-rr-delete-document',
        subNavItems: [
            {
                label: 'No sender Contact details',
                route: 'non-user-no-contact-thankyou-list',
            },
            {
                label: 'Thank you list',
                route: 'non-user-thankyou-list',
            },
            {
                label: 'Failed Thank you',
                route: 'non-user-moderation-failed-thankyou-list',
            }
        ]
    },
    {
        label: 'Gifting',
        iconClass: 'fi fi-rr-gift',
        subNavItems: [
            {
                label: 'Gifting',
                route: 'gift',
            },
            {
                label: 'Gift Partner',
                route: 'gift-partner',
            },
            {
                label: 'Orders',
                route: 'orders',
            },
        ],
    },
    {
        label: 'News and Stories',
        route: 'news-stories',
        iconClass: 'fi fi-rr-document-signed',
    },
    {
        label: 'Events',
        iconClass: 'fi fi-rr-calendar',
        subNavItems: [
            {
                label: 'Events List',
                route: 'event',
            },
            {
                label: 'Event Categories',
                route: 'event-categories',
            },
            {
                label: 'Archived Event',
                route: 'archived-event',
            },
            {
                label: 'Location Greetings',
                route: 'location-greetings',
                iconClass: 'fi fi-rr-heart',
            },
        ],
    },
    {
        label: 'Help Center',
        iconClass: 'fi fi-rr-headset',
        subNavItems: [
            {
                label: 'How To',
                route: 'how-to-list'
            },
            {
                label: 'Quick Tip',
                route: 'quicktip',
            },
            {
                label: 'FAQ’s',
                route: 'faq',
            },
            {
                label: 'Terms of Service',
                route: 'terms-of-service',
            },
            {
                label: 'Acceptable Use Policy',
                route: 'acceptable-policy',
            },
            {
                label: 'Privacy Policy',
                route: 'privacy-policy',
            },
            {
                label: 'Privacy Setting',
                route: 'privacy-setting',
            },
        ],
    },
    {
        label: 'Charity',
        iconClass: 'fi fi-rr-hand-holding-heart',
        subNavItems: [
            {
                label: 'Charity List',
                route: 'charity',
            },
            {
                label: 'Charity Categories',
                route: 'charity-categories',
            },
            {
                label: 'Archived Charity',
                route: 'archived-charity',
            },
        ],
    },
    // {
    //     label: 'Announcements',
    //     iconClass: 'fi fi-rr-megaphone',
    //     subNavItems: [
    //         {
    //             label: 'Quick Tip',
    //             route: 'quicktip',
    //         },
    //     ],
    // },
    {
        label: 'Admin Setting',
        iconClass: 'fi fi-rr-settings',
        subNavItems: [
            {
                label: 'User Settings',
                route: 'admin-settings',
            },
            {
                label: 'Welcome email settings',
                route: 'welcome-email-settings',
            },
            {
                label: 'Currency settings',
                route: 'currency-settings',
            }
        ],
    },
    {
        label: 'App QR Code',
        route: 'app-qr-code',
        iconClass: 'fi fi-rr-qrcode',
    },
];

export default function SideBar() {
    const [openNavItemIndex, setOpenNavItemIndex] = useState(null);

    return (
        <>
            <nav id="sidebar" className="sidebar-wrapper">
                <div className="sidebar-content">
                    <div className="sidebar-brand">
                        <NavLink to="/">
                            <img src={logo} alt="" />
                        </NavLink>
                    </div>

                    <ul className="sidebar-menu">
                        {navItems.map((navItem, i) => {
                            const hasSubNavItems = navItem.subNavItems && navItem.subNavItems.length > 0;

                            return (
                                <li
                                    key={i}
                                    className={`${hasSubNavItems ? 'sidebar-dropdown' : ''} ${i === openNavItemIndex ? "active" : ""}`}
                                >
                                    {hasSubNavItems ? (
                                        <>
                                            <a href="#" onClick={() => setOpenNavItemIndex(i === openNavItemIndex ? null : i)}>
                                                <i className={navItem.iconClass}></i> {navItem.label}
                                            </a>
                                            <div className={`sidebar-submenu ${i === openNavItemIndex ? "d-block" : ""}`}>
                                                <ul>
                                                    {navItem.subNavItems.map((subNavItem, j) => (
                                                        <li key={j}>
                                                            <NavLink
                                                                to={subNavItem.route}
                                                                className={({ isActive }) => isActive ? "active-nav-in" : ""}
                                                            >
                                                                {subNavItem.label}
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </>
                                    ) : (
                                        <NavLink
                                            to={navItem.route}
                                            className={({ isActive }) => (isActive ? "active-nav" : "")}
                                            onClick={() => setOpenNavItemIndex(null)}
                                        >
                                            <i className={navItem.iconClass}></i> {navItem.label}
                                        </NavLink>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </nav>
        </>
    );
}
